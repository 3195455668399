<template>
	<div class="page">
		<div class="w1200 spell_page" @scroll="handleScroll">
			<!-- 音频 -->
			<audio controls ref="commonaudioCom" class="disnone">
				<source src="" />
			</audio>
			<div class="block48"></div>
			<!-- <topHeader></topHeader> -->
			<div class="spell_box_one">
				<div class="titilebox" :class="{'fHeight':scrollHeight>188}" :style="{top:(scrollHeight-92)+'px'}">
					<div class="leftbox">
						<img @click="handleclose" class="leftgo" src="../assets/static/left_go.png"/>
						<div class="text1" v-if="type==0 || type==1">
							<span>综合测评</span>
						</div>
						<div class="text1" v-if="type==2">
							<span>单元测试</span>
						</div>
					</div>
					<div class="centerbox">
						<span v-if="!showallanswer">剩余时间：{{timerText}}</span>
						<span v-if="showallanswer">完成时间：{{timerText}}</span>
					</div>
					<div class="leftbox"></div>
					<!-- <div class="titlename_box">
						<div class="itemobj fontsize14" :class="{'active':index==bookdtypeindex}" v-for="(item,index) in bookdtypeList" :key="index">
							{{item}}
						</div>
					</div> -->
				</div>
				<div class="spellconbox">
					<div class="titletypebox" v-if="wordoptionskcxy.length>0">
						<div class="text">看词选义</div>
					</div>
					<div class="kcxylistbox">
						<div class="itemobj" v-for="(item,index) in wordoptionskcxy" :key="index">
							<div class="box1">
								<div class="textindex">{{index+1}}.</div>
								<div class="linsten-btn" @click="playAudioCom(item.phAmMp3)">
									<img class="img100" src="../assets/static/linsten.png"/>
								</div>
								<div class="text">{{item.word}}</div>
							</div>
							<div class="box2">
								<div class="optionitem" :class="{'active':item.word==sitem.word&&showallanswer}" v-for="(sitem,sindex) in item.selectDTOList" :key="sindex" @click="handleSelect(1,index,sindex)">
									<div class="selicon">
										<img v-if="sitem.isselect" class="img100" src="../assets/static/check-circle-filled.png"/>
										<img v-else class="img100" src="../assets/static/circle.png"/>
									</div>
									<div class="word-desc clamp" v-if="sitem.wordTpye.length>0">
									    <span>{{ sitem.wordTpye[0].part }}</span>
									    <span class="desc2" v-for="(mitem, mindex) in sitem.wordTpye[0].means"
									    	:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
									    </span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="titletypebox" v-if="wordoptionstyxy.length>0">
						<div class="text">听音选义</div>
					</div>
					<div class="kcxylistbox">
						<div class="itemobj" v-for="(item,index) in wordoptionstyxy" :key="index">
							<div class="box1">
								<div class="textindex">{{index+1+wordoptionskcxy.length}}.</div>
								<div class="linsten-btn" @click="playAudioCom(item.phAmMp3)">
									<img class="img100" src="../assets/static/linsten.png"/>
								</div>
								<!-- <div class="text">{{item.word}}</div> -->
							</div>
							<div class="box2">
								<div class="optionitem" :class="{'active':item.word==sitem.word&&showallanswer}" v-for="(sitem,sindex) in item.selectDTOList" :key="sindex" @click="handleSelect(2,index,sindex)">
									<div class="selicon">
										<img v-if="sitem.isselect" class="img100" src="../assets/static/check-circle-filled.png"/>
										<img v-else class="img100" src="../assets/static/circle.png"/>
									</div>
									<div class="word-desc clamp" v-if="sitem.wordTpye.length>0">
									    <span>{{ sitem.wordTpye[0].part }}</span>
									    <span class="desc2" v-for="(mitem, mindex) in sitem.wordTpye[0].means"
									    	:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
									    </span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="titletypebox" v-if="dataList.length>0">
						<div class="text">听音拼写</div>
					</div>
					<div class="datalist">
						<div class="itemobj" v-for="(item,index) in dataList" :key="index">
							<div class="linsten-block">
								<div class="textindex">{{index+1+wordoptionskcxy.length+wordoptionstyxy.length}}.</div>
								<div class="linsten-btn" @click="playAudioCom(item.phAmMp3)">
									<img class="img100" src="../assets/static/linsten.png"/>
								</div>
								<div class="word-desc clamp" v-if="item.showCn&&item.wordTpye.length>0">
								    <span>{{ item.wordTpye[0].part }}</span>
								    <span class="desc2" v-for="(mitem, mindex) in item.wordTpye[0].means"
								    	:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
								    </span>
								</div>
								<div class="btnshow" v-if="!item.showCn" @click="handleShowCn(index)">显示释义</div>
							</div>
							<div class="wordbc">
								<div class="label1">输入单词：</div>
								<div class="inputlistbox" @click="playAudioCom(item.phAmMp3)">
									<input class="input" placeholder="请输入" :ref="`input${index}`" v-model="wordListValue[index]" @input="handleinput(index)" @keydown.enter="handleinputenter(index)"/>
								</div>
							</div>
							<div class="wordbc1" v-if="showallanswer">
								<div class="text1" :class="{'active':wordListValue[index] == item.word.replace(/[^a-zA-Z0-9]/g, '')}">正确答案：<span>{{item.word}}</span></div>
							</div>
						</div>
					</div>
					<div class="block30"></div>
					<div class="btn1" @click="handletijiao" v-if="!showallanswer">交卷</div>
					<div class="btn_cancel" v-if="showallanswer" @click="showfinish = true">关闭</div>
					<div class="block30"></div>
				</div>
			</div>
			
		</div>
		
		<div class="block30"></div>
		<!-- 完成 -->
		<!-- 完成测试 -->
		<div class="show_tc_box" v-if="showfinish">
			<div class="zezhao10"></div>
			<div class="w1200 finish_box">
				<div class="imgclose" @click="handleComfirm">
					<img class="img100" src="../assets/static/Frame3465600.png"/>
				</div>
				<div class="title1">
					<img v-if="type==2" class="img100" src="../assets/static/dycs.png"/>
					<img v-if="type==1" class="img100" src="../assets/static/zhcp.png"/>
				</div>
				<div class="desc1">
					<div class="text1">{{score}}</div>
					<div class="text2">你的分数</div>
				</div>
				<div class="conbox">
					<div class="text text1">正确题目：<span>{{duiNum}}</span></div>
					<div class="text text2">错误题目：<span>{{cuoNum}}</span></div>
				</div>
				<div class="btnbox">
					<div class="btn1 btn2" @click="handleshowanswer">查看答案</div>
					<div class="btn1" @click="handleComfirm">Fighting</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	import topHeader from "@/components/topHeader/topHeader.vue";
	export default {
		components:{
			topHeader
		},
		data() {
			return {
				type:0,//默认1是词书测评， 2是从单元来的
				bookdtypeList:["单词速记","词义连连","听音拼写","单元测试"],
				bookdtypeindex:2,//下标
				dataList:[],
				timer:null,
				totalSeconds: 90,
				useSeconds: 0,
				timerText:"01:30",//时间
				showCn:false, //中文
				wordListValue:[],//新建输入框，解决无法双向绑定问题
				duiNum:0,//对
				cuoNum:0,//对
				score:0,//分数
				showfinish:false,//完成
				wordoptionskcxy:[],//看词选意
				wordoptionstyxy:[],//听音选意思
				scrollHeight:0,//页面滚动高度
				lessonexamobj:{
					lessonId:null,
					bookId:null,
				},
				showallanswer:false,//展示所有答案
			};
		},
		mounted: function() {
			var _this = this
			window.addEventListener('scroll', this.handleScroll);
			if(this.$route.query&&this.$route.query.type){
				this.type = Number(this.$route.query.type)
			}else{
				this.type = 1
			}
			this.wordListValue = []
			this.duiNum = 0 //对
			this.cuoNum = 0//对
			this.score = 0 //分数
			if(this.type == 1){
				if(this.myReadingObj){
					this.useSeconds = 0
					this.totalSeconds = 2400,
					this.timerText = "40:00"//时间
					//获取总词书题目
					this.getlevelTest()
				}else{
					this.$confirm('当前暂无数据可测评，请返回重试！', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '返回首页',
						type: 'warning'
					}).then(() => {
						_this.sysgoback()
					}).catch(() => {
						_this.$router.replace("/")
					});
					return;
				}
			}else{
				//单元测评
				if(this.studyingData.lessonexamobj&&this.studyingData.lessonexamobj.lessonId){
					this.useSeconds = 0
					this.totalSeconds = 900,
					this.timerText = "15:00"//时间
					this.lessonexamobj = this.studyingData.lessonexamobj
					//获取单元题目
					this.getuniTest()
				}else{
					this.$confirm('当前暂无可拼写数据，请返回重试！', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '返回首页',
						type: 'warning'
					}).then(() => {
						_this.sysgoback()
					}).catch(() => {
						_this.$router.replace("/")
					});
					
				}
			}
			
		},
		beforeDestroy() {
		    // 清除定时器
			if(this.timer){
				clearInterval(this.timer);
			}
			window.removeEventListener('scroll', this.handleScroll);
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'studyingData']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO"]),
			//查看答案
			handleshowanswer(){
				this.showallanswer = true
				this.showfinish = false
				this.dataList.forEach((item, index) => {
					item.showCn = true
				});
				//完成试卷时间转换
				let minutes = Math.floor(this.useSeconds / 60);
				let seconds = this.useSeconds % 60;
				minutes = minutes < 10 ? '0' + minutes : minutes;
				seconds = seconds < 10 ? '0' + seconds : seconds;
				this.timerText = minutes + ':' + seconds;
			},
			//关闭
			handleclose(){
				var _this = this
				if(this.showallanswer){
					//完成跳转
					this.handleComfirm()
					return;
				}
				this.$confirm("当前处于测评中，确认是否退出？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.sysgoback()
				}).catch(() => {});
			},
			handleScroll(event) {
			    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			    this.scrollHeight = scrollTop;
			    //console.log('滚动高度:', scrollTop);
			    // 在这里可以根据滚动高度进行相应的处理
			},
			navigato(url,query) {
				this.$util.routerPath(url,query)
			},
			//选择题
			handleSelect(type,index,oindex){
				if(this.showallanswer){
					return;
				}
				if(type==1){
					//看词选义
					this.wordoptionskcxy[index].selectDTOList.forEach((mitem, mindex) => {
						mitem["isselect"] = false
					});
					this.wordoptionskcxy[index].keyword = this.wordoptionskcxy[index].selectDTOList[oindex].word
					this.wordoptionskcxy[index].selectDTOList[oindex].isselect = true
					
				}else if(type==2){
					//听音选义
					this.wordoptionstyxy[index].selectDTOList.forEach((mitem, mindex) => {
						mitem["isselect"] = false
					});
					this.wordoptionstyxy[index].keyword = this.wordoptionstyxy[index].selectDTOList[oindex].word
					this.wordoptionstyxy[index].selectDTOList[oindex].isselect = true
				}else{
					
				}
			},
			//获取总词书题目
			getlevelTest(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					booksId:this.myReadingObj.bookId,
					status:0,
					wordNum:50
				}
				showLoading()
				_this.$http.post("app/word/booksLesson/levelTest", params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						res.data.wordList1.forEach((item, index) => {
							item["keyword"] = ""
							item.selectDTOList.forEach((mitem, mindex) => {
								mitem["isselect"] = false
								try{
									mitem["wordTpye"] =  JSON.parse(mitem.definition)
								}catch(e){
									mitem["wordTpye"] = []
								}
							});
						});
						res.data.wordList2.forEach((item, index) => {
							item["keyword"] = ""
							item.selectDTOList.forEach((mitem, mindex) => {
								mitem["isselect"] = false
								try{
									mitem["wordTpye"] =  JSON.parse(mitem.definition)
								}catch(e){
									mitem["wordTpye"] = []
								}
							});
						});
						res.data.wordList3.forEach((item, index) => {
							item["showCn"] = false
							item["testStatus"] = 0 //1是正确，2是错误
							try{
								item["wordTpye"] =  JSON.parse(item.definition)
							}catch(e){
								item["wordTpye"] = []
							}
							//新建输入框，解决无法双向绑定问题
							_this.wordListValue.push("")
						});
						_this.wordoptionskcxy = res.data.wordList1 //看词选意
						_this.wordoptionstyxy = res.data.wordList2 //听音选意思
						_this.dataList = res.data.wordList3 //pinxie
						// 创建定时器
						_this.timer = setInterval(_this.updateCountdown, 1000);
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//单元测试提米
			getuniTest(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					booksId:this.lessonexamobj.bookId,
					lessonId:this.lessonexamobj.lessonId,
					status:0,
					wordNum:999
				}
				showLoading()
				_this.$http.post("app/word/booksLesson/uniTest", params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						res.data.wordList1.forEach((item, index) => {
							item["keyword"] = ""
							item.selectDTOList.forEach((mitem, mindex) => {
								mitem["isselect"] = false
								try{
									mitem["wordTpye"] =  JSON.parse(mitem.definition)
								}catch(e){
									mitem["wordTpye"] = []
								}
							});
						});
						res.data.wordList2.forEach((item, index) => {
							item["keyword"] = ""
							item.selectDTOList.forEach((mitem, mindex) => {
								mitem["isselect"] = false
								try{
									mitem["wordTpye"] =  JSON.parse(mitem.definition)
								}catch(e){
									mitem["wordTpye"] = []
								}
							});
						});
						res.data.wordList3.forEach((item, index) => {
							item["showCn"] = false
							item["testStatus"] = 0 //1是正确，2是错误
							try{
								item["wordTpye"] =  JSON.parse(item.definition)
							}catch(e){
								item["wordTpye"] = []
							}
							//新建输入框，解决无法双向绑定问题
							_this.wordListValue.push("")
						});
						_this.wordoptionskcxy = res.data.wordList1 //看词选意
						_this.wordoptionstyxy = res.data.wordList2 //听音选意思
						_this.dataList = res.data.wordList3 //pinxie
						// 创建定时器
						_this.timer = setInterval(_this.updateCountdown, 1000);
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//关闭
			handleclosefun(){
				this.showfinish = false
			},
			//完成
			async handleComfirm(){
				if(this.type==1){
					//词书
					this.navigato('/',{})
				}else{
					//单元
					// this.sysgoback()
					this.navigato('studyPage',{type:3})
				}
			},
			//提交
			handletijiao(){
				var _this = this
				_this.$confirm("确定要交卷?", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					showLoading()
					//选择题
					_this.wordoptionskcxy.forEach((item, index) => {
						if(item.keyword == item.word){
							_this.duiNum +=1
						}else{
							_this.cuoNum +=1
						}
					});
					//选择题
					_this.wordoptionstyxy.forEach((item, index) => {
						if(item.keyword == item.word){
							_this.duiNum +=1
						}else{
							_this.cuoNum +=1
						}
					});
					//拼写判断
					_this.dataList.forEach((item, index) => {
						if(_this.wordListValue[index]){
							const word = item.word.replace(/[^a-zA-Z]/g, "").toLowerCase()
							const keyword = _this.wordListValue[index].replace(/[^a-zA-Z]/g, "").toLowerCase()
							if(keyword == word){
								_this.duiNum +=1
							}else{
								_this.cuoNum +=1
							}
						}else{
							_this.cuoNum +=1
						}
					});
					var alllength = Number(_this.wordoptionskcxy.length + _this.wordoptionstyxy.length + _this.dataList.length)
					//分数
					_this.score = Number(((_this.duiNum / alllength)  * 100).toFixed(0));
					_this.showfinish = true
					if(_this.timer){
						clearInterval(_this.timer);
					}
					hideLoading()
					//保存记录
					if(_this.type == 1){
						//词书
						var params = {
							score:_this.score,
							bookId:_this.myReadingObj.bookId,
							userUuid:_this.userInfo.uuid,
						}
						_this.$common.saveLevelRecord(params)
					}else if(_this.type == 2){
						//单元
						var params = {
							score:_this.score,
							recordType:3,//测试类型 0:单词速记 1:单词连连 2:听音拼写 3:单元测试
							bookId:_this.lessonexamobj.bookId,
							booksLessonId:_this.lessonexamobj.lessonId,
							userUuid:_this.userInfo.uuid,
						}
						_this.$common.saveBooksLessonRecord(params)
						var studyingData = _this.studyingData
						studyingData.lessonexamobj = {
							lessonId:null,
							bookId:null,
						}
						_this.SET_Data({ key: 'studyingData', value:studyingData})
					}else{}
				}).catch(() => {});
			},
			//展示中文
			handleShowCn(index){
				this.dataList[index].showCn = true
			},
			//输入监听
			handleinput(index){
				this.wordListValue[0] = this.wordListValue[0].replace(/[^a-zA-Z0-9]/g, '')
			},
			//输入回车监听
			handleinputenter(index){
				if(this.showallanswer){
					return;
				}
				console.log("回车",index)
				var newindex = index + 1
				if(newindex>=this.dataList.length){
					return;
				}else{
					this.playAudioCom(this.dataList[newindex].phAmMp3)
					const nextInput = this.$refs[`input${newindex}`];
					//console.log(nextInput)
					if (nextInput) {
					    nextInput[0].focus();
					}
				}
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//下标
			handlebookdtype(type){
				this.bookdtypeindex = type
			},
			// 更新倒计时
			updateCountdown() {
				if (this.totalSeconds > 0) {
				    this.totalSeconds -= 1;
					this.useSeconds +=1
					let minutes = Math.floor(this.totalSeconds / 60);
					let seconds = this.totalSeconds % 60;
					minutes = minutes < 10 ? '0' + minutes : minutes;
					seconds = seconds < 10 ? '0' + seconds : seconds;
					this.timerText = minutes + ':' + seconds;
				} else {
					// this.totalSeconds = 2400,
					// this.timerText = "40:00"//时间
					if(this.timer){
						clearInterval(this.timer);
					}
					//提交，结果，时间到
					this.handletijiao()
				}
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	.spell_page{
		position: relative;
		z-index: 2;
	}
	.titletypebox{
		color: #222;
		font-size: 25px;
		margin-top: 38px;
	}
	.spell_box_one{
		width: 100%;
		padding: 0 32px;
		box-sizing: border-box;
		position: relative;
		border-radius: 20px;
		background: linear-gradient(180deg, #FFF 16.74%, #F2F5FA 60.03%, #E1EAFB 99.98%);
		.titilebox{
			width: 100%;
			height: 92px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #F0F0F0;
			.leftbox{
				width: 328px;
				display: flex;
				align-items: center;
				.leftgo{
					width: 32px;
					height: 32px;
					cursor: pointer;
				}
				.text1{
					margin-left: 12px;
					color: #222;
					font-size: 24px;
				}
			}
			.centerbox{
				color: #273B90;
				font-size: 20px;
			}
			.titlename_box{
				width: 328px;
				padding: 5px;
				display: flex;
				align-items: center;
				border-radius: 100px;
				border: 1px solid #E5E6EB;
				background-color: #ffffff;
				.itemobj{
					width: 80px;
					height: 28px;
					line-height: 28px;
					text-align: center;
					border-radius: 100px;
					background: #ffffff;
					color: #666;
				}
				.active{
					background: #3773F5;
					color: #ffffff;
				}
			}
			&.fHeight{
				width: 100%;
				padding: 0 32px;
				box-sizing: border-box;
				position: absolute;
				// top: 60px;
				left: 0;
				z-index: 8;
				background-color: #ffffff;
			}
		}
		.spellconbox{
			width: 100%;
			// height: 600px;
			// overflow-y: auto;
			.kcxylistbox{
				width: 100%;
				.itemobj{
					margin-top: 48px;
					width: 100%;
					.box1{
						width: 100%;
						display: flex;
						align-items: center;
						.textindex{
							color: #273B90;
							font-size: 32px;
							flex-shrink: 0;
							margin-right: 8px;
						}
						.linsten-btn{
							width: 48px;
							height: 48px;
							cursor: pointer;
							flex-shrink: 0;
						}
						.text{
							flex: 1;
							margin-left: 8px;
							color: #222;
							font-size: 26px;
						}
					}
					.box2{
						width: 100%;
						margin-top: 8px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						.optionitem{
							width: 48%;
							padding: 10px;
							box-sizing: border-box;
							display: flex;
							align-items: center;
							cursor: pointer;
							.selicon{
								width: 28px;
								height: 28px;
								flex-shrink: 0;
							}
							.word-desc {
								flex: 1;
								margin-left: 8px;
								color: #222;
								font-size: 20px;
								.desc2{
									margin-left: 4px;
								}
							}
						}
						.active{
							background: rgb(225, 243, 216);
							border-radius: 8px;
							font-weight: bold;
						}
					}
				}
			}
			.datalist{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.itemobj{
					width: 46%;
					margin-top: 32px;
					.wordbc{
						width: 100%;
						display: flex;
						align-items: center;
						margin-top: 8px;
						.label1{
							width: 100px;
							display: flex;
							align-items: center;
							font-size: 20px;
							color: #000000;
							flex-shrink: 0;
						}
						.inputlistbox{
							flex: 1;
							display: flex;
							align-items: center;
							.input{
								width: 100%;
								height: 42px;
								line-height: 42px;
								color: #000000;
								border-radius: 6px;
								border: 1px solid #0194FF;
								background: #FFFFFF;
								font-size: 16px;
								padding: 0 12px;
								box-sizing: border-box;
							}
						}
					}
					.wordbc1{
						height: 58px;
						width: 100%;
						display: flex;
						align-items: center;
						color: #222;
						font-size: 20px;
						.text1{
							display: flex;
							align-items: center;
							font-size: 20px;
							span{
								color: #F50;
							}
						}
						.active{
							span{
								color: #4CDE62 !important;
							}
						}
					}
				}
				.linsten-block{
					width: 100%;
					display: flex;
					align-items: center;
					.textindex{
						color: #273B90;
						font-size: 32px;
						flex-shrink: 0;
						margin-right: 8px;
					}
					.linsten-btn{
						width: 48px;
						height: 48px;
						cursor: pointer;
						flex-shrink: 0;
					}
					.text{
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
						text-align: right;
					}
					.btnshow{
						margin-left: auto;
						width: 112px;
						height: 42px;
						color: #FFFFFF;
						line-height: 42px;
						text-align: center;
						border-radius: 12px;
						background: #3773F5;
						font-size: 20px;
						cursor: pointer;
					}
					.word-desc {
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 22px;
						.desc2{
							margin-left: 4px;
						}
					}
				}
			}
		}
		.btn1{
			width: 160px;
			margin: 18px auto 0px;
			height: 48px;
			color: #0772FF;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
			font-size: 20px;
			cursor: pointer;
		}
		.btn_cancel{
			width: 160px;
			margin: 18px auto 0px;
			height: 48px;
			color: #fff;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: #999;
			font-size: 20px;
			cursor: pointer;
		}
	}
	.finish_box{
		padding: 58px 28px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 9999;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
		.title1{
			margin-top: 18px;
			width: 384px;
			height: 63px;
		}
		.desc1{
			margin-top: 28px;
			width: 82%;
			height: 185px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			border-radius: 20px;
			border: 3px solid #50FFFF;
			background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
			.text1{
				color: #F50;
				font-size: 64px;
				text-align: center;
			}
			.text2{
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
		}
		.conbox{
			width: 100%;
			margin-top: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			.text{
				color: #FFF;
				font-size: 28px;
				span{
					color: #273B90;
				}
			}
			.text2{
				margin-left: 28px;
			}
		}
		.btnbox{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn2{
				margin-right: 28px;
			}
		}
		.btn1{
			margin-top: 48px;
			width: 160px;
			height: 48px;
			color: #0772FF;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
			font-size: 20px;
			cursor: pointer;
		}
	}
</style>